<template>
  <div id="app-ad-save" class="app-add">
    <loading :loading="loading" :text="loadingText"></loading>
    <el-form ref="form" :model="ad"  label-width="100px">
      <el-form-item label="广告位" :required="true" >
        <el-select v-model="ad.positionId" placeholder="请选择" style="width: 100%" @change="handleChange">
          <el-option
              v-for="position in positions"
              :key="position.id"
              :label="position.name"
              :value="position.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告名称" :required="true">
        <el-input type="text"  v-model="ad.name" placeholder="请输入"></el-input>
      </el-form-item>
      <upload-form-item ref="picUrl"  @parentEvent="getPicUrl"></upload-form-item>
      <el-form-item label="链接类型" :required="true">
        <el-radio v-model="ad.linkType" :label="0">广告内容</el-radio>
        <el-radio v-model="ad.linkType" :label="1">课程详情</el-radio>
      </el-form-item>
      <el-form-item label="链接地址" v-show="ad.linkType==1" :required="true">
        <el-input type="text"  v-model="ad.linkUrl" placeholder="请输入课程ID"></el-input>
      </el-form-item>
      <el-form-item label="广告内容" v-show="ad.linkType==0">
        <quill-editor class="editor" @focus="quill = this" v-model="ad.content" :options="quillOption"></quill-editor>
        <div class="layui-input-block">
          <span style="color:#666;padding:5px;display: block;">图宽750、图高不限、单图不超500K</span>
        </div>
      </el-form-item>
      <el-form-item label="排序" :required="true" v-show="this.id != ''">
        <el-input type="number"  v-model="ad.sort"  placeholder="请输入"></el-input>
      </el-form-item>
      <el-button @click="submit()" id="layuiadmin-app-form-submit" style="display: none">提交</el-button>

    </el-form>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import {request, TipEnum, UrlEnum,getLocalProjectId,LinkTypeEnum} from "../../../public/js/common-vue";
import UploadFormItem from "@/components/UploadFormItem";
import quillConfig from "@/assets/js/quill-config";
import {SESSON_KEY_AD_POSITIONID} from "../../../public/js/sesson-key-vue";

export default {
  name: "AdSave",
  data() {
    return {
      loading: false,
      loadingText:"",
      positions:[],
      id:'',
      quillOption: quillConfig,
      position:{},
      ad:{
        projectId:'',
        positionId:'',
        name:'',
        picUrl:'',
        linkType:0,
        linkUrl:'',
        content:'',
        sort:''
      },
    }
  },
  components: {UploadFormItem, Loading},
  methods:{
    handleChange(id){
      this.setPicSize(id);
    },
    setPicSize(id){
      for(let i=0;i<this.positions.length;i++){
        if(this.positions[i].id == id){
          let width = this.positions[i].width;
          let height = this.positions[i].height;
          this.$refs.picUrl.setSize(width + "x" + height);
        }
      }
    },
    getPicUrl(data){
      this.ad.picUrl = data;
    },
    getPositions() {
      this.loadingText="加载中";
      this.loading=true;
      const url = UrlEnum.AD_POSITIONS;
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.positions = res.data.list;
        this.setPicSize(this.ad.positionId);
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    },
    submit(){
      if(this.ad.positionId == ""){
        this.showMsgError("请选择广告位");
        return;
      }
      if(this.ad.name == ""){
        this.showMsgError("请输入广告名称");
        return;
      }
      if(this.ad.picUrl == ""){
        this.showMsgError("请上传广告图片");
        return;
      }
      this.ad.projectId = getLocalProjectId();
      if((this.ad.linkType == LinkTypeEnum.COURSE_DETAIL) && this.ad.linkUrl == ""){
        this.showMsgError("请输入课程ID");
        return;
      }
      sessionStorage.setItem(SESSON_KEY_AD_POSITIONID,this.ad.positionId);
      let url = UrlEnum.ADS;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }

      this.loadingText="提交中";
      this.loading = true;
      this.$http({
        method: method,
        url: url,
        data: this.ad,
      })
      .then((res) => {
        this.loading = false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loadingText="加载中";
      this.loading=true;
      const url = UrlEnum.ADS + "/" + this.id
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.ad = res.data;
        this.$refs.picUrl.init("广告图片",true,'',this.ad.picUrl);
        this.getPositions();
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    }
  },
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
    else{
      this.getPositions();
      let sessionPositionId = sessionStorage.getItem(SESSON_KEY_AD_POSITIONID);
      if(sessionPositionId != null && sessionPositionId != ""){
        this.ad.positionId = parseInt(sessionPositionId);
      }
      this.$refs.picUrl.init("广告图片",true,'600 x 800',this.ad.picUrl);
    }
  }
}
</script>

<style>
@import "../../../static/css/add.css";
@import "../../../static/css/editor.css";
.editor{
  height: 500px;
  margin-bottom: 90px;
}
</style>
